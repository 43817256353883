import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Humanity/2023/1.jpg';
// import p2 from 'assests/Photos/Clubs/Humanity/2023/2.jpg';
// import p3 from 'assests/Photos/Clubs/Humanity/2023/3.jpg';
// import p4 from 'assests/Photos/Clubs/Humanity/2023/4.jpg';
// import p5 from 'assests/Photos/Clubs/Humanity/2023/5.jpg';
// import p6 from 'assests/Photos/Clubs/Humanity/2023/6.jpg';
// import p7 from 'assests/Photos/Clubs/Humanity/2023/7.jpg';
// import p8 from 'assests/Photos/Clubs/Humanity/2023/8.jpg';


// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from './Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';


const Humanity2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/Humanity/Humanity8-12/8.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      HUMANITIES ASSOCIATION REPORT 2023
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 8 - 12  &  Date: 27 JUNE 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Humanities Association organised the first inaugural edition of NPS YPR MUN (Model United Nations) on Tuesday 
        27 June 2023 for the students of classes 8 to 12. This was an educational simulation of diplomacy, understanding 
        of international relationship and crisis. Delegations representing different countries participated in the session 
        forming Future Crisis Committee to debate on the topic ‘A potential nuclear war between Russia and USA’.
        The delegates formulated resolutions to prevent a potential war by avidly debating on the topic and it was a 
        good sign of 'munning' by NPS YPR students. The resolution in the end was a successful one, thus planting their 
        first pavestone for creating a long history of MUNs in the future.
        <br></br>
        Overall, this session was taken in a good stride by all participants who deliberated with great vigour and 
        energetically portrayed their thoughts, opinions and wishes during the committee.
        {/* <br></br>
        The Principal Ms Sheeja Manoj, addressed the students and highlighted the importance of keeping themselves updated 
        on the environmental concerns and being thoughtful about their actions. The students thoroughly enjoyed the activity 
        and it proved to be a valuable learning experience. */}
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “ALL FOR MUN, MUN FOR ALL”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Humanity2023;